import { useEffect, useState } from 'react';
import { initMercadoPago, Wallet } from '@mercadopago/sdk-react';

import { API_URL } from '../../constants';

initMercadoPago('TEST-ef6a241b-b316-4191-bc7b-269b3e5ca34a');

function MercadoPagoBtn() {
  const [preferenceId, setPreferenceId] = useState(null);
  //planId 2c93808494dc05c90194e26acb5603b0
  //correo

  //create subscription, post a /plans
  const createPreference = async () => {
    const res = await fetch(API_URL + '/create-payment', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*'
      },
      body: JSON.stringify({
        title: 'Mensualidad',
        price: 50
      })
    });
    const data = await res.json();
    setPreferenceId(data.id);
  };

  const customization = {
    texts: {
      valueProp: 'smart_option'
    }
  };

  const onSubmit = async formData => {
    // callback llamado al hacer clic en Wallet Brick
    // esto es posible porque Brick es un botón
  };

  const onError = async error => {
    // callback llamado para todos los casos de error de Brick
    console.log(error);
  };

  const onReady = async () => {
    // Callback llamado cuando Brick esté listo.
    // Aquí puedes ocultar loadings en tu sitio, por ejemplo.
  };

  useEffect(() => {
    createPreference();
  }, []);

  return preferenceId ? (
    <Wallet
      initialization={{ subscriptionId: preferenceId }}
      customization={customization}
      onSubmit={onSubmit}
      onReady={onReady}
      onError={onError}
    />
  ) : (
    <p>Cargando...</p>
  );
}

export default MercadoPagoBtn;
