import { defineMessages, MessageDescriptor } from 'react-intl';

interface OAuthLoginMessages {
  [key: string]: MessageDescriptor;
}

const messages: OAuthLoginMessages = defineMessages({
  loading: {
    id: 'cboard.components.OAuthLogin.loading',
    defaultMessage: 'Loading...'
  },
  errorMessage: {
    id: 'cboard.components.OAuthLogin.errorMessage',
    defaultMessage: 'An error has occurred...'
  }
});

export default messages;
