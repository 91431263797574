import React, { useState, useEffect } from 'react';
import {
  initMercadoPago,
  createCardToken,
  CardNumber
} from '@mercadopago/sdk-react';
import FullScreenDialog from '../../components/UI/FullScreenDialog';
import { TextField } from '../../components/UI/FormItems';
import { API_URL } from '../../constants';

const MercadoPagoForm = () => {
  // eslint-disable-next-line no-undef
  useEffect(() => {
    initMercadoPago('TEST-ef6a241b-b316-4191-bc7b-269b3e5ca34a', {
      locale: 'es-MX' // Ajusta según el país
    }); // Reemplaza con tu clave pública
  }, []);

  const [cardToken, setCardToken] = useState(null);
  const [status, setStatus] = useState(null);

  const handleTokenization = async event => {
    event.preventDefault();

    const cardData = {
      cardNumber: '4509953566233704', // Número de tarjeta de prueba
      cardExpirationMonth: '11',
      cardExpirationYear: '2025',
      securityCode: '123',
      cardholderName: 'APRO',
      identificationType: 'DNI',
      identificationNumber: '12345678'
    };

    try {
      const tokenResponse = await createCardToken(cardData);
      setCardToken(tokenResponse.id);
      console.log('Card Token:', tokenResponse.id);
    } catch (error) {
      console.error('Error al generar el token:', error);
    }
  };

  const enviarPago = async () => {
    const response = await fetch(API_URL + '/plans', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        cardTokenId: cardToken,
        panId: '2c93808494dc05c90194e26acb5603b0'
      })
    });

    const data = await response.json();
    console.log('Respuesta del pago:', data);
  };

  const handleGoBack = () => {
    window.location.replace('/settings/subscribe');
  };

  return (
    <FullScreenDialog
      open
      title={'Mercado Pago'}
      onClose={handleGoBack}
      // fullWidth
    >
      <h2>Pago con Tarjeta</h2>
      <form className="Login__form" onSubmit={handleTokenization}>
        <TextField
          placeholder="Número de Tarjeta"
          //   error={errors.email}
          label="Número de Tarjeta"
          name="cardNumber"
          //   onChange={handleChange}
        />
        {/* <CardNumber placeholder="Card number" /> */}
        <TextField
          placeholder="MM"
          //   error={errors.email}
          label="Mes"
          name="cardExpirationMonth"
          //   onChange={handleChange}
        />
        <TextField
          placeholder="YY"
          //   error={errors.email}
          label="Año"
          name="cardExpirationYear"
          //   onChange={handleChange}
        />
        <TextField
          placeholder="CVV"
          //   error={errors.email}
          label="CVV"
          name="securityCode"
          //   onChange={handleChange}
        />
        <TextField
          //   error={errors.email}
          label="Nombre Titular"
          name="cardholderName"
          //   onChange={handleChange}
        />
        <TextField
          //   error={errors.email}
          label="DNI"
          name="identificationNumber"
          //   onChange={handleChange}
        />

        <button type="submit">Generar Token</button>
      </form>

      {cardToken && <p>Token de la Tarjeta: {cardToken}</p>}
      <button onClick={enviarPago}>comprar</button>

      {/* <CardPayment
      initialization={{ amount: 100 }}
      onSubmit={async (param) => {
        console.log(param);
      }}
    /> */}
    </FullScreenDialog>
  );
};

export default MercadoPagoForm;
